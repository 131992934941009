import React from "react";
import LogoImg from "../../Assets/logo2.png";

function Logo() {
  return (
    <div>
      <img src={LogoImg} className="h_logo" alt="" />
    </div>
  );
}

export default Logo;
